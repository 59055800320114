/**
 * 获得一个object的值
 */
 export function getObjectValue(objectInfo, ...allArgs) {
    if (allArgs.length < 1) {
        return null;
    }
    let defaultValue = '';
    if (allArgs.length > 1) {
        defaultValue = allArgs[(
            allArgs.length - 1
        )];
        allArgs = allArgs.slice(0, -1);
    }
    let returnValue = objectInfo;
    for (let index = 0; index < allArgs.length; index++) {
        const fieldKey = allArgs[index];
        if (typeof returnValue == 'object' &&
            null != returnValue &&
            returnValue[fieldKey] != 'undefined') {
                returnValue = returnValue[fieldKey];
        } else {
            returnValue = null;
        }
        if (index == allArgs.length - 1) {
            if (null != returnValue) {
                return returnValue;
            }
            return defaultValue;
        }
    }
    return defaultValue;
}