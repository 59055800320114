import { formatAPIData } from '../../../../functions/string/formatAPIData';
import { getObjectValue } from '../../../../functions/string/getObjectValue';
import { GUEST_TYPE, CUSTOMER_TYPE } from '../../types';

/**
 * 得到当前的版本
 */
 const currentApiVersion = 103;
 export function getCurrentApiVersion() {
     return currentApiVersion;
 }

export default magento => ({
  login: ({ email, password }) =>
    magento.post(
      '/V1/integration/customer/token',
      undefined,
      { password, username: email },
      GUEST_TYPE,
    ),

  signup: ({ firstName, lastName, email, password }) => {
    const requestBody = {
      customer: {
        email,
        firstname: firstName,
        lastname: lastName,
      },
      password,
    };
    return magento.post('/V1/customers', undefined, requestBody, GUEST_TYPE);
  },

  getCurrency: () =>
    magento.get('/V1/directory/currency', undefined, undefined, GUEST_TYPE),

  getCountries: () =>
    magento.get('/V1/directory/countries', undefined, undefined, GUEST_TYPE),

  resetPassword: ({ email }) =>
    magento.put(
      '/V1/customers/password',
      undefined,
      {
        email,
        template: magento.configuration.password_reset_template,
        websiteId: magento.storeConfig.website_id,
      },
      GUEST_TYPE,
    ),

    /**
     * 得到签到task
     */
    registerBeansGetSignInTasks: (requestBody = undefined) => {
      const currentApiVersion = getCurrentApiVersion();
      if (typeof requestBody != 'object' || null == requestBody) {
        requestBody = {
          currentApiVersion: currentApiVersion
        };
      } else {
        requestBody.currentApiVersion = currentApiVersion;
      }
      return formatAPIData(() => {
        return magento.post(
          '/V1/registerBeans/getSignInTasks', undefined, requestBody, CUSTOMER_TYPE
        )
      }, {
        success: (r) => {
          const data = getObjectValue(r, 'data', {});
          const isShowRegisterRewardMsg = getObjectValue(data, 'isShowRegisterRewardMsg', false);
          // const registerRewardMsgTitle = getObjectValue(data, 'registerRewardMsgTitle', '');
          // const registerRewardMsgDesc = getObjectValue(data, 'registerRewardMsgDesc', '');

          if (!isShowRegisterRewardMsg) {
            return;
          }
          setTimeout(() => {
            // 提示注册成功
          }, 1000);
        }
      });
    },

    /**
     * 签到送积分
     */
    signInTasksNow: () => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/registerBeans/signInTasksNow', undefined, undefined, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 得到账户积分流水
     */
    getBeansAccountLog: (params = undefined) => {
      return formatAPIData(() => {
        return magento.get(
          '/V1/beansAccountLog', params, undefined, CUSTOMER_TYPE
        )
      });
    },


    /**
     * get-green-beans-task-lists
     */
    getGreentBeansTaskLists: (params = undefined) => {
      const currentApiVersion = getCurrentApiVersion();
      if (typeof params != 'object' || null == params) {
        params = {
          currentApiVersion: currentApiVersion
        };
      } else {
        params.currentApiVersion = currentApiVersion;
      }
      return formatAPIData(() => {
        return magento.get(
          '/V1/greentBeansTaskLists', params, undefined, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 谷歌看视频送积分
     */
    admobCongraitulationGetBeans: (params = undefined) => {
      return formatAPIData(() => {
        return magento.get(
          '/V1/admob/congraitulationGetBeans', params, undefined, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 使用beans购买
     */
     addOrderByUseBeans: (params = undefined) => {
      return formatAPIData(() => {
        return magento.get(
          '/V1/beans/addOrder', params, undefined, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 得到当前的服务器时间
     */
     getCurrentServerDate: (params = undefined) => {
      return formatAPIData(() => {
        return magento.get(
          '/V1/getCurrentServerDate', params, undefined, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 自动登录, 通过唯一key
     */
     automaticLogon: (params = undefined) => {
      return formatAPIData(() => {
        return magento.get(
          '/V1/automaticLogon', params, undefined, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 得到好友列表
     */
     getFriendsLists: (params = undefined) => {
      const currentApiVersion = getCurrentApiVersion();
      if (typeof params != 'object' || null == params) {
        params = {
          currentApiVersion: currentApiVersion
        };
      } else {
        params.currentApiVersion = currentApiVersion;
      }
      return formatAPIData(() => {
        return magento.get(
          '/V1/getFriendsLists', params, undefined, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 上传用户头像
     */
     uploadUserAvatar: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/uploadUserAvatar', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 收割豆子api
     */
     harvestBeansApi: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/harvestBeans', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },
    /**
     * 购买口罩, 通过豆子
     */
     buyMachineByBeans: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/buyMachineByBeans', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 通过步行获得豆子
     */
    getBeansByStepCounts: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/getBeansByStepCounts', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },
    
    /**
     * 做任务奖励豆子
     */
     rewardBeansForTask: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/rewardBeansForTask', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 得到用户自定义轮廓信息
     */
     getUserCustomProfileUser: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/getUserCustomProfileUser', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 得到paypal braintree客户端token
     */
     getBraintreeClientToken: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/getBraintreeClientToken', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 得到paypal braintree客户端token
     */
     braintreeTransactionSale: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/braintreeTransactionSale', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 对购物车进行结算
     */
     createOrderByCarts: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/createOrderByCarts', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 对购物车进行结算
     */
     getCartProductStocks: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/getCartProductStocks', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 得到商户名下所有的机器
     */
     getMerchantAllMachineDetails: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/getMerchantAllMachineDetails', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 操作机器相关信息
     */
     opMachineRelevantInformation: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/opMachineRelevantInformation', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },

    /**
     * 通过机器编号得到机器的状态
     */
     getMachineStatusInfoByMachineCode: (requestBody = undefined) => {
      return formatAPIData(() => {
        return magento.post(
          '/V1/getMachineStatusInfoByMachineCode', undefined, requestBody, CUSTOMER_TYPE
        )
      });
    },
});
