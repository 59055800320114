export default {
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user name"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "pleaseFillInCorrectUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the correct user name"])},
  "pleaseFillInCorrectUserPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the correct user password"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
  "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed, please try again"])},
  "module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "prevText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "nextText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "tatalPageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "pullingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull down refresh"])},
  "loosingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release to refresh"])},
  "loadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "finishedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All have been loaded"])},
  "merchantNoMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no machine under this merchant"])}
}