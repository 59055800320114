export default {
  "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
  "pleaseFillInCorrectUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写正确的用户名"])},
  "pleaseFillInCorrectUserPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写正确的用户密码"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中..."])},
  "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录失败, 请重试"])},
  "module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模块"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详情"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测试"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "prevText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上一页"])},
  "nextText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一页"])},
  "tatalPageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总页数"])},
  "pullingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下拉即可刷新"])},
  "loosingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["释放即可刷新."])},
  "loadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中..."])},
  "finishedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已全部加载完成"])},
  "merchantNoMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该商户下没有机器"])}
}